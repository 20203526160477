define('appConfig',[], function () {
  'use strict';

  return {
    apiServer: {
      protocol: 'http',
      hostname: 'vitals-tracker.herokuapp.com',
      port: '80'
    }
  };
});

