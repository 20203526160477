define('plugins/index',[
  'module_interfaces/jqueryCore',
  'plugins/trackFocus',
  'plugins/typedInput',
  'plugins/dateTimePickerInput',
  'plugins/privateData'
], function ($) {
  'use strict';

  return $;
});

