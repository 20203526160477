define('module_interfaces/bootstrap',[
  'module_interfaces/jqueryCore',
  'bootstrap.affix',
  'bootstrap.alert',
  'bootstrap.button',
  'bootstrap.carousel',
  'bootstrap.collapse',
  'bootstrap.dropdown',
  'bootstrap.modal',
  'bootstrap.popover',
  'bootstrap.scrollspy',
  'bootstrap.tab',
  'bootstrap.tooltip',
  'bootstrap.transition',
  'bootstrap-datetimepicker'
], function ($) {
  'use strict';

  //console.log('boostrap');
  // Nothing
  return $;
});

